.multiStepsFormPage {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
}

.circlesDiv {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.circleDiv {
  display: flex;
  align-items: center;
}

.circleDiv:last-child {
  width: fit-content;
}

.circle {
  width: fit-content;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  border-width: 3px;
  border-radius: 9999px;
}

.circleActive {
  width: fit-content;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  border-width: 3px;
  border-radius: 9999px;
  --tw-border-opacity: 1;
  color: white;
}

.circleSpan {
  height: 0.175rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.circleSpanActive {
  height: 0.125rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.lastSpanHidden {
  display: none;
}

.multiStepsForm {
  width: 100%;
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.multiStepsNextButtonDiv {
  display: flex;
  align-items: center;
  width: fit-content;
}

h2 {
  font-weight: 700;
}

.multistepsinput {
  background-color: white;
  color: black;
  border: 2px solid #cecece;
  border-radius: .5rem;
  padding: 8px 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.CostumerMultiStepsLabel {
  font-weight: bold;
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
}

.multiStepsselect {
  height: 54px;
  background-color: white;
  color: black;
  border: 2px solid #cecece;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px 24px;
  border-radius: .5rem;
}

.modal_inactive {
  display: none;
}

.modal_active {
  background-color: #000000ab;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vehicles_table::-webkit-scrollbar {
  display: none;
}

@media (min-width: 640px) {
  .circlesDiv {
    width: 80%;
    font-size: 2rem;
    margin: 0;
  }

  .circle {
    font-size: 1.8rem;
    padding: 0 0.8rem;
    border-width: 3px;
  }

  .circleActive {
    font-size: 1.8rem;
    padding: 0 0.8rem;
    border-width: 3px;
  }
}

@media (min-width: 1024px) {
  .circlesDiv {
    width: 50%;
    font-size: 2.5rem;
    margin: 1.1rem 0;
  }

  .circle {
    font-size: 1.8rem;
    padding: 0 0.8rem;
    border-width: 3px;
  }

  .circleActive {
    font-size: 1.8rem;
    padding: 0 0.8rem;
    border-width: 3px;
  }

  .multiStepsForm {
    width: 60%;
    min-height: 55vh;
  }

  .CostumerMultiStepsLabel {
    font-size: 1.125rem;
    margin-top: 2px;
  }
}
